import { Link, Outlet } from "react-router-dom";
import { useEffect } from "react";

function Other() {
  useEffect( () => {
    document.title = 'other';
  }, []);

  return <div>
    <div
      style={{
        textAlign: 'center',
        transformStyle: 'preserve-3d',
        perspective: '1000',
        padding: '0 1rem',
      }}
    >
      <h1
        style={{
          fontSize: 0,
          height: 0,
          margin: 0,
        }}
      >
        other
      </h1>

      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '512px',
          margin: '1rem auto',
          padding: '0.25rem 1rem',
          lineHeight: 1.5,
        }}
      >
        <h2>things i like to do</h2>
        <p>
          <Link to="/other/music">music</Link>
          /
          <Link to="/other/lyrics">lyrics</Link>
          /
          <Link to="/other/writing">writing</Link>
          /
          <Link to="/other/art">art</Link>
        </p>
      </div>

      <div>
        <Outlet />
      </div>
    </div>

  </div>
}

export default Other;

