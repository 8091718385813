import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const MARKDOWN_CONTENT = `

---

# Hypercycling
  
* storyboard / timeline
  * sections / broad strokes?
* "lyrics" -> content
* do a bunch of "components" -> animation language
* hook onto a track's metadata
* ...
  * interactivity?
  * show/hide lyrics
  * themeable?

## Timeline

* intro
  * landscape pan
  * 
* getting into the nitty gritty
  * visuals
  * asym lines flashing
  * spiralling / twisting
* hypercycling
  * zooming in / out
* triplets
  * “birth and rebirth”
* more landscapes
* ambient soundscape + weird visuals
* ...
* driving bass sections
  * falling “deeper”
  * ever zoom
  * “hypercycling” shouts
  * faces in horror
  * twisted
  * laughing maniacally

* disbeliever
* dark green
* ...


`;

function MarkdownContent() {
  useEffect( () => {
    document.title = 'yo';
  }, []);

  return <div>
    <div
      style={{
        padding: '0 0 5rem',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p><small>Back to <Link to="/">home page</Link>.</small></p>
        <hr />
      </div>

      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '666px',
          margin: '1rem auto',
          padding: '0.25rem 1rem',
          lineHeight: 1.5,
        }}
      >

        <Markdown>{MARKDOWN_CONTENT}</Markdown>
      </div>
    </div>

  </div>
}

export default MarkdownContent;

