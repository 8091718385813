// import Markdown from "react-markdown";
// import { Link } from "react-router-dom";
// import { useEffect } from "react";

const MARKDOWN_CONTENT = `

`;

export default function Music() {
  return (
    <div>
      meh.
    </div>
  );
}
