export default `

# Milledenial

Millenial Dissing
Seeding Dissent
Just my two cents
On documents leaking
On machine learning
And marine leaching
Or coral bleaching
Clock is ticking
And guns are cocking
Youth is stressing
Battling depression
Fighting malign teachings
Social net stretches
As pressure builds up
Boots are stomping
In streets marching
And malice is leaking
These memes mesmerize
Memory shortcuts
Our masters short circuit
Democracy's last gasp

Tell me a thing or two or maybe a 1000
Beg to differ but what's the differential?
If I'm so high it's cause I'm in denial
Yes I was a kid at the turn of the millenium

Sucking droplets of gas
Teetering on the edge
Nothing else matters
Capital's fleeing
Bunkers barricaded
Racing to the bottom
Bottom feeding frenzy
Friends is merchandize
Traded on the daily
Wages flatlining
Profits are soaring
Sky's the limit
A moving goal post
Heated eject
Seat of my pants
Future is bleak
Brink of decay
Can't look away
Anchor's aweigh
Trainwreck havoc

`;
