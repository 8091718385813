const cyclical = `

# Cyclical

It's a cyclical cynical thing
What you'd call a clinical case
Of extreme skeptical thinkin'
Comes and goes like ocean tides
Issues aroused when tedium arose
Bored and morose, a case of case closed
Clearly in need of daily doses of doubt

Pick up the truth hose and douse
Until drenched and complacent
Only eyes to see, eyes deceived
Idle hands, sheer will deceased
Sore brain muscles, wits decreased
Err on the side of caution
Ideas kosher, no hydration
Lubed on grease escapes crises
Peace treaties signed with ease
Treatise on Middle-East, my treat

Political talk got me mental
Everybody's opinions on appeal
Keep up with posts, your eyes peeled
Got my disbelief in suspension
This big goofy compulsion
To gobble ever more information
And regurgitate gobs of lies daily
Some drink the Koolaid while some brew it
Bring a saucepan and cook it
Cauldron of misdirection simmered to perfection
Seemingly immune to suspicion

`;

export default cyclical;
