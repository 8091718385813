export default function Gallery(props) {
    const {
        images = []
    } = props || null;

    return (
        <div
          className="Gallery"
        >
          {images.map( (l, i) => {
            const {
              path,
            } = l || {};
            return (
              <img key={path} src={path} alt={`Image ${i} (of ${images.length})`} />
            );
          })}
        </div>
    );
}
