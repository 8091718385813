import Extra from "./Extra";

import "./Experience.css";

export default function Experience(props) {
    const {
        id = null,
        title = null,
        company = null,
        location = null,
        url = null,
        description = null,
        extra = null,
        mode = null, // null / "print"
    } = props;

    return (
        <div className="Experience">
            <div className="Experience__intro">
                <h4 className="Experience__dates">
                    {title}
                </h4>
            </div>
            <div className="Experience__content">
                <h3 className="Experience__company">
                    {company}
                </h3>

                <div className="Experience__context">
                    {location ? (
                        <small>{location}</small>
                    ) : null}
                    {location && url ? (
                        <span>&nbsp;/&nbsp;</span>
                    ) : null}
                    {url ? (
                        <small>
                            <a
                                href={url}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {url}
                            </a>
                        </small>
                    ) : null}
                </div>

                {mode !== 'print' ? (
                    <p className="Experience__description">{description}</p>
                ) : null}

                {(mode === 'full' && extra !== null && extra.length > 0) ? (
                    <ul className="Experience__extras">
                        {extra.map(Extra)}
                    </ul>
                ) : null}

            </div>
        </div>
    );
}