import { Link } from "react-router-dom";
import { useCallback, useEffect, useState, useRef } from "react";
import { useSpring, animated as a } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

import Scanner from "../../components/viz/Scanner";

function Phone() {
  useEffect( () => {
    document.title = 'x/phone';
  }, []);

  const ref = useRef(null);
  const [dragState, setDragState] = useState({});

  const bind = useDrag(e => {
    if (!ref || !ref.current) {
      return;
    }
    const {xy, active, delta, movement, direction} = e || {};
    const [dx, dy] = delta || [];
    const [x, y] = xy || [];
    const { width, height } = ref.current.getBoundingClientRect();
    const ratioX = x / width; 
    const ratioY = y / height;

    setDragState({ x, y, ratioX, ratioY, dx, dy, active });
  }, [setDragState]);

  const {
    x,
    y,
    ratioX,
    ratioY,
    dx,
    dy,
    active
  } = dragState;

  return <div>
    <div
      style={{
        padding: '0 0 5rem',
      }}
    >

      <Scanner
          label={`ratioX`}
          use={active ? ratioX : 0}
          processor={n => -2 * n}
          history={128}
          offset={100}
          // linecolor={`rgb(255 0 255 / 1`}
          withValue
        />
        <Scanner
          label={`ratioY`}
          use={active ? ratioY : 0}
          processor={n => -2 * n}
          history={128}
          offset={100}
          // linecolor={`rgb(255 0 255 / 1`}
          withValue
        />

      <div
        style={{
          // position: 'fixed',
          // top: 0,
          // left: 0,
          // bottom: 0,
          // right: 0,
          width: '360px',
          height: '720px',
          backgroundColor: 'rgb(255 255 0 / 1)',
          touchAction: 'none',
          userSelect: 'none',
        }}
        {...bind()}
        ref={ref}
      >
    

        <pre>
          yeah
        </pre>

      </div>    
    </div>
  </div>
}

export default Phone;

