const dowsing = `

# Dowsing

Extract a meaning from every moment
Compare and measure the difference
Long time spent in deference
Even longer spent in resentment

Conscious to tick the minutes off
The past is lost to the void
All choice is made and so what
Existence is an absurd machine

When the land is dry and lonely
Inner voices won’t suffice
Another boring roll of the dice
Results are dissatisfactory

Superimpose your arbitrary laws
On my rotten substrate
Chaos is my inconsistent state
An ineffable sense of utter loss

Now giving away all control
Everything is dead, yet all alive
Higher the fall, deeper the dive
Such tribulation force me to a crawl

`;

export default dowsing;
