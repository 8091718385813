import Project from "../work/Project";

// import "./Extra.css";

// import projects from "../../data/projects.json";

export default function Extra(props) {
    const {
        label = null,
        href = null,
        description = null,
        projectId = null,
    } = props || {};

    return (
        <li key={label}>
            <p className="Extra__project">
                {href !== null
                    ? (
                        <a
                            className="Extra__link"
                            href={href}
                            target="_blank"
                        >
                            {label}
                        </a>
                    )
                    : label}
            </p>

            {description !== null ? (
                <p
                    className="Extra__description"
                >
                    {description}
                </p>
            ) : null}
        </li>
    );
}