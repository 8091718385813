import { Link } from "react-router-dom";
import { useCallback, useEffect, useState, useRef } from "react";
import { useSpring, animated as a } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

import Scanner from "../../components/viz/Scanner";

function Stripped() {
  useEffect( () => {
    document.title = 'x/stripped';
  }, []);

  const [dragState, setDragState] = useState({});

  const bind = useDrag(e => {
    const {xy, active, delta, movement, direction} = e || {};
    const [dx, dy] = delta || [];
    const [x, y] = xy || [];
    const ratioX = x / window.innerWidth;
    const ratioY = y / window.innerHeight;

    setDragState({ x, y, ratioX, ratioY, dx, dy, active });
    console.log({ratioX, ratioY});
  }, [setDragState]);

  const {
    x,
    y,
    ratioX,
    ratioY,
    dx,
    dy,
    active
  } = dragState;

  return <div>
    <div
      style={{
        padding: '0 0 5rem',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p><small>Back to <Link to="/">home page</Link>.</small></p>
        <hr />
      </div>

      <h1
        style={{
          textAlign: 'center',
          margin: '2rem 0 0',
        }}
      >
        it's a drag
      </h1>

      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(255 255 0 / 1)',
          touchAction: 'none'
        }}
        {...bind()}
      >
        <Scanner
          label={`ratioX`}
          use={active ? ratioX : 0}
          processor={n => -2 * n}
          history={128}
          offset={100}
          // linecolor={`rgb(255 0 255 / 1`}
          withValue
        />
        <Scanner
          label={`ratioY`}
          use={active ? ratioY : 0}
          processor={n => -2 * n}
          history={128}
          offset={100}
          // linecolor={`rgb(255 0 255 / 1`}
          withValue
        />

        <pre>
          yeah
        </pre>

      </div>    
    </div>
  </div>
}

export default Stripped;

