// import Markdown from "react-markdown";
// import { Link } from "react-router-dom";
// import { useEffect } from "react";

const MARKDOWN_CONTENT = `

`;

export default function Writing() {
  return (
    <div>
      i write about some stuff sometimes
    </div>
  );
}
