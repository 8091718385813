import { Link } from "react-router-dom";
import { useCallback, useEffect, useState, useRef } from "react";
import Diamond from "../assets/symbols/Diamond";

function Now() {
  useEffect( () => {
    document.title = 'now';
  }, []);

  return <div>
    <div
      style={{
        padding: '0 0 5rem',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p><small>Back to <Link to="/">home page</Link>.</small></p>
        <hr />
      </div>

      <h1
        style={{
          textAlign: 'center',
          margin: '2rem 0 0',
        }}
      >
        now
      </h1>

      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '666px',
          margin: '1rem auto',
          padding: '0.25rem 1rem',
          lineHeight: 1.5,
        }}
      >
        <h2>december 19, 2023</h2>

        <ul
          style={{
            listStyleType: 'upper-roman',
          }}
        >
          <li>
            <p>happy holidays to everyone!</p>
          </li>
          <li>
            <p>seasonal depression is in full effect; doing everything I can to fight it</p>
          </li>
          <li>
            <p>om</p>
          </li>
        </ul>

        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Diamond />
        </div>

      </div>
    </div>

  </div>
}

export default Now;

