import { useEffect } from "react";
import { Link } from "react-router-dom";

import Project from "../work/Project";
import Circle from "../../assets/symbols/Circle";
import Wave from "../../assets/symbols/Wave";
import Star from "../../assets/symbols/Star";

import projects from "../../data/projects.json";

import "./Home.css";

function Home() {
  useEffect(() => {
    document.title = 'fredmercy';
  }, []);

  return <div
    className="Home"
  >
    <div className="Home__section">
      <Circle style={{
        marginTop: '2rem',
        width: '4rem',
        height: '4rem',
      }} />

      <div className="Home__intro1">
        <p>
          hi! my name is<br /><strong className="Home__name">Fred Mercy</strong>
        </p>
        <p>and i have been<br /><strong>building interactive interfaces</strong><br />for <em>almost 20 years</em> now</p>
      </div>

      <Wave style={{
        width: '4rem',
        height: '4rem',
      }} />

      <div className="Home__intro2">
        <p>i am a bit of a<br /><em>chronically curious nerd</em><br />who enjoys working out the technical nitty-gritty of<br /><em>pretty much everything</em></p>
        <p>i also have a<br /> <em>deep love</em><br /> for the creative process<br /> and artistic expression in general</p>
      </div>

      <Wave style={{
        width: '4rem',
        height: '4rem',
      }} />
      
      <p>here is a selection of<br /><strong>projects</strong><br /> on which I worked.</p>
      <p>(you may also <Link to="/resume">read my résumé</Link>)</p>
    </div>

    {projects && projects.length ? (
      <div>
        {projects.map((p, i) => {
          const { id } = p || {};

          return (
            <Project
              key={id}
              {...p}
            />
          );
        })}
      </div>
    ) : null}

    <div className="Home__section">
      <Wave style={{
        width: '4rem', 
        height: '4rem', 
      }}/>

      <p>
        Thank you so much for taking the time to browse my portfolio!
      </p>
      <p>
        I'm currently available for work.
      </p>
      <p>If you are interested in hiring me, you may want to <Link to="/resume">read my résumé</Link>.</p>

      <Circle style={{
        width: '4rem', 
        height: '4rem', 
        marginTop: '2rem',
        marginBottom: '10rem',
      }}/>

    </div>
  </div>
}

export default Home;
