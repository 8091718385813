import "./Skill.css";

export default function Skill(props) {
  const {
    id,
    title,
    lines,
  } = props;

  return (
    <div className="Skill">
      <h4 className="Skill__heading">{title}</h4>
      <ul className="Skill__list">
        {lines.map(line => <li key={line} className="Skill__item">{line}</li>)}
      </ul>
    </div>
  );
}