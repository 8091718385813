export default `

# Head First

Lethal
Dose of madness
Cripples my brain
My own decisions

It’s crazy
How far down I sunk
To the bottom
My own damn fault

Cruel
This world of yours
I must traverse
Unhindered

Dodging
The common mistakes
But sometimes
Falling for them

Head first

`;
