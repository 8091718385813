import { Link } from "react-router-dom";
import * as Tone from "tone";
import { useCallback, useEffect, useState, useRef } from "react";
import { useSpring, animated as a } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

import useRaf from "../../hooks/useRaf";

function Stripped1() {
  const pink = useRef();
  const lowPassFilter = useRef();

  useEffect( () => {
    document.title = 'x/stripped';
  }, []);

  const [dragState, setDragState] = useState({});

  const bind = useDrag(e => {
    const {xy, initial, active, first, last, delta, distance, offset, movement, direction, velocity} = e || {};
    const [dx, dy] = delta || [];
    const [x, y] = xy || [];
    const [startX, startY] = initial || [];
    const ratioX = x / window.innerWidth;
    const ratioY = y / window.innerHeight;
    const [distanceX, distanceY] = distance || [];
    const [offsetX, offsetY] = offset || [];
    const diffX = Math.abs(startX - x);
    const diffY = Math.abs(startY - y);
    const dist = Math.sqrt( diffY**2 + diffX**2 )

    // create on first interaction
    if (!pink.current && first) {
      pink.current = new Tone.Noise('pink');
      lowPassFilter.current = new Tone.Filter(0, "lowpass").toDestination();
      pink.current.connect(lowPassFilter.current).start();
    }

    if (lowPassFilter.current && active) {
      lowPassFilter.current.set({
        frequency: `${dist * 2}Hz`
      });
    }

    if (lowPassFilter.current && last) {
      // console.log({velocity, movement, x, y}); 
      lowPassFilter.current.set({
        frequency: '0Hz'
      });
    }

    setDragState({ x, y, startX, startY, ratioX, ratioY, dx, dy, active });
    console.log({ratioX, ratioY});
  }, [setDragState]);

  const {
    x,
    y,
    startX,
    startY,
    ratioX,
    ratioY,
    dx,
    dy,
    active
  } = dragState;

  const [enemyX, setEnemyX] = useState(0);
  const [enemyY, setEnemyY] = useState(100);

  useRaf(({t, dt}) => {
    const incX = enemyX + 3;
    const nextX = incX > window.innerWidth ? 0 : incX;

    // const incY = Math.sin(t * 0.005) * 10;
    // const nextY = enemyY + incY;

    setEnemyX(nextX);
    // setEnemyY(nextY);
  }, [enemyX, enemyY, setEnemyX, setEnemyY]);

  return <div>
    <div
      style={{
        padding: '0 0 5rem',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p><small>Back to <Link to="/">home page</Link>.</small></p>
        <hr />
      </div>

      <h1
        style={{
          textAlign: 'center',
          margin: '2rem 0 0',
        }}
      >
        it's a drag
      </h1>

      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(255 255 0 / 1)',
          touchAction: 'none'
        }}
        {...bind()}
      >

        {/* HIT */}
        {active ? (
          <div
            style={{
              position: `absolute`,
              width: `32px`,
              height: `32px`,
              backgroundColor: `rgb(255 0 255 / 1)`,
              borderRadius: `50%`,
              transform: `
                translate(-50%, -50%)
                translate(${startX}px, ${startY}px)
              `,
            }}
          >
          </div>
        ): null}

        <div
          style={{
            position: `absolute`,
            width: `128px`,
            height: `128px`,
            backgroundColor: `rgb(255 0 0 / 1)`,
            transform: `
              translate(-50%, -50%)
              translate(${enemyX}px, ${enemyY}px)
            `,
          }}
        >
          enemy?
        </div>
      </div>    
    </div>
  </div>
}

export default Stripped1;

