import { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Diamond from "../../assets/symbols/Diamond";

import Gallery from './Gallery';

import HYPERCYCLING from '../../data/HYPERCYCLING.json';
import LANDSCAPES from '../../data/LANDSCAPES.json';
import BENDS from '../../data/BENDS.json';

import "./Art.css";

function Art() {
  useEffect( () => {
    document.title = 'art';
  }, []);

  return <div>
    <div
      style={{
        textAlign: 'center',
        transformStyle: 'preserve-3d',
        perspective: '1000',
        padding: '0 1rem',
      }}
    >
      <h1
        style={{
          fontSize: 0,
          height: 0,
          margin: 0,
        }}
      >
        art
      </h1>

      <div>
        <h2>HYPERCYCLING</h2>

        <Gallery images={HYPERCYCLING} />

        <h2>BENDS</h2>

        <div
          className="Art__image-grid"
        >
          {BENDS.map( (l, i) => {
            const {
              path,
              filename,
              file_size
            } = l || {};
            return (
              <img key={path} src={path} alt={`BENDS image series ${i}/${BENDS.length}`} />
            );
          })}
        </div>

        <Diamond />

        <h2>LANDSCAPES</h2>

        <div
          className="Art__image-grid"
        >
          {LANDSCAPES.map( (l, i) => {
            const {
              path,
              filename,
              file_size
            } = l || {};
            return (
              <img key={path} src={path} alt={`LANDSCAPES image series ${i}/${LANDSCAPES.length}`} />
            );
          })}
        </div>
      </div>
    </div>
  </div>
}

export default Art;

