import Home from './pages/home/Home';
import Now from './pages/Now';
import Other from './pages/other/Other';
import Art from './pages/art/Art';
import Music from './pages/other/music/Music';
import Lyrics from './pages/other/lyrics/Lyrics';
import Writing from './pages/other/writing/Writing';

import Resume from './pages/resume/Resume';

import XmasCard2023 from './pages/other/XmasCard2023';

import Stripped from './pages/x/Stripped';
import Stripped1 from './pages/x/Stripped1';
import Space from './pages/space/Space';
import Gluub from './pages/space/Gluub';
import Phone from './pages/x/Phone';
import Scratchpad from './pages/MarkdownContent';

// import MarkdownContent from './pages/MarkdownContent';

export const routes = [
  { path: '/', element: <Home />, },
  { path: '/now', element: <Now />, },
  { path: '/other', element: <Other />, children: [
    { path: 'art', element: <Art />, },
    { path: 'other', element: <Other />, },
    { path: 'lyrics', element: <Lyrics />, },
    { path: 'music', element: <Music />, },
    { path: 'writing', element: <Writing />, },
  ]},

  { path: 'cv', element: <Resume lang="fr" /> },
  { path: 'cv/imprimable', element: <Resume lang="fr" mode="print" hideWave /> },
  { path: 'resume', element: <Resume /> },
  { path: 'resume/print', element: <Resume mode="print" hideWave /> },
  { path: 'resume/full', element: <Resume mode="full" hideWave /> },

  { path: '/x/stripped', element: <Stripped /> },
  { path: '/x/stripped1', element: <Stripped1 /> },
  { path: '/x/phone', element: <Phone /> },
  { path: '/y', element: <Scratchpad /> },
  { path: '/xmas-card-2023', element: <XmasCard2023 /> },

  { path: '/x/space', element: <Space /> },
  { path: '/x/gluub', element: <Gluub /> },
  { path: '/y', element: <Scratchpad /> },
];

