import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function LayoutSimplePage(props) {
  const {
    title = '—',
    children = null,
  } = props || {};

  useEffect( () => {
    document.title = title;
  }, []);

  return <div>
    <div
      style={{
        padding: '0 0 5rem',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p><small>Back to <Link to="/">home page</Link>.</small></p>
        <hr />
      </div>

      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '666px',
          margin: '1rem auto',
          padding: '0.25rem 1rem',
          lineHeight: 1.5,
        }}
      >
        {children}
      </div>
    </div>

  </div>
}
