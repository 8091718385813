import "./Education.css";

export default function Education(props) {
  const {
    id,
    title,
    lines,
  } = props;

  return (
    <div className="Education">
      <h4 className="Education__heading">{title}</h4>
      <ul className="Education__list">
        {lines.map(line => <li key={line} className="Education__item">{line}</li>)}
      </ul>
    </div>
  );
}