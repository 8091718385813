import Markdown from "react-markdown";
// import { Link } from "react-router-dom";
import LayoutSimplePage from "../../../components/LayoutSimplePage";
import Diamond from "../../../assets/symbols/Diamond";

// import { useEffect } from "react";
import cyclical from "./cyclical";
import dowsing from "./dowsing";
import headfirst from "./headfirst";
import milledenial from "./milledenial";

export default function Lyrics() {
  return (
    <LayoutSimplePage title="lyrics">
      <pre>
        <Markdown>{cyclical}</Markdown>
        <Diamond size={10} />
        <Markdown>{dowsing}</Markdown>
        <Diamond size={10} />
        <Markdown>{headfirst}</Markdown>
        <Diamond size={10} />
        <Markdown>{milledenial}</Markdown>
      </pre>
    </LayoutSimplePage>
  );
}
