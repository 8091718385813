import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import Experience from "./Experience";
import Skill from "./Skill";
import Education from "./Education";

import Circle from "../../assets/symbols/Circle";
import Wave from "../../assets/symbols/Wave";

import resume from "../../data/fred_resume.json";
import resumeFrench from "../../data/fred_cv.json";

import "./Resume.css";

const types = {
  experience: Experience,
  skills: Skill,
  education: Education,
};

const __ = function (lang, strings) {
  const yo = strings[lang];
  return yo;
}

function Resume(props) {
  const {
    lang = 'en',
    mode = 'web',
    hideWave = false,
  } = props || {};

  useEffect(() => {
    document.title = __(lang, {
      en: 'résumé',
      fr: 'cv',
    });

    window.scrollTo(0, 0);
  }, [lang]);

  return <div className="Resume__main">
    <div className="Resume__intro">
      <h1 className="Resume__heading">Frederic Mercy</h1>
      <div className="Resume__bio">
        {__(lang, {
          en: (
            <p>frontend developer,<br />multimedia artist</p>
          ),
          fr: (
            <p>développeur frontend,<br />artiste multimédia</p>
          ),
        })}
      </div>
    </div>

    {__(lang, { en: resume, fr: resumeFrench }).map(section => {
      const { id, heading, items } = section;
      const SectionComponent = types[id];

      if (!SectionComponent) {
        return false;
      }

      return (
        <div
          key={id}
          style={{
            breakBefore: id !== 'experience' ? 'page' : 'auto',
          }}
        >
          {hideWave
            ? null
            : (
              <Wave
                className="Resume__separator"
                style={{
                  display: 'block',
                  width: '4rem',
                  height: '4rem',
                }}
              />
            )}

          <h2 className="Resume__heading">
            {heading}
          </h2>

          {items.map(item => {
            const { id } = item;
            return (
              <div key={id}>
                <SectionComponent {...item} mode={mode} />
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
}

export default Resume;
